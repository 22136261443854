<template>
  <default-layout>
    <div class="intro-ekyc default-layout-main">
      <h3 class="font-bold text-center text-2xl mt-4">Hướng dẫn eKYC</h3>
      <p class="text-center mt-2">
        eKYC là công nghệ định danh điện tử được Ngân hàng Nhà nước khuyến khích
        sử dụng trong lĩnh vực tài chính, ngân hàng. Giúp bạn đơn giản hóa các
        thủ tục, giấy tờ.
      </p>
      <div class="text-left w-11/12 m-auto mt-10 mb-14">
        <p class="my-4">Các bước cần chuẩn bị để eKYC</p>
        <p class="my-4">Bước 1: Chụp mặt trước CMND/CCCD</p>
        <p class="my-4">Bước 2: Chụp sau trước CMND/CCCD</p>
        <p class="my-4">Bước 3: Chụp ảnh khuôn mặt (Selfie)</p>
      </div>
      <base-button
        @click="handleSubmit"
        class="w-full border-16 bg-tertiary text-white py-6"
        >Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>

<script>
import ConstRouter from '../../constants/constRouter';
import DefaultLayout from '@/layouts/DefaultLayout';
import BaseButton from '@/components/Base/BaseButton';
export default {
  name: 'EkycIntro',
  components: { BaseButton, DefaultLayout },
  data() {
    return {};
  },
  methods: {
    handleSubmit() {
      this.$router.push({ name: ConstRouter.EKYC.name });
    },
  },
};
</script>
